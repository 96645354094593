import { colors } from "@components/application/Theme"
import resp from "@utils/resp"
import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding-top: 81px;
  }

  .app__banner {
    /* padding: 8.7rem 6.9rem 9.25rem 9rem; */
    padding-top: ${resp(139)};
    padding-right: ${resp(110)};
    padding-bottom: ${resp(30)};
    padding-left: ${resp(148)};

    @media (max-width: 768px) {
      padding: 3.8rem 1.8rem;
    }
    @media (max-width: 480px) {
      padding: 3.8rem 1.8rem;
    }
  }

  .intro__grid {
    /* display: flex; */
    text-align: center;
    width: 75%;
    margin: 0 auto;

    @media (max-width: 768px) {
      text-align: center;
      margin: 0 auto;
      width: calc(100% - 10px);
    }
  }

  @media (max-width: 768px) {
    .download__sect {
      display: flex;
      justify-content: center;
    }
  }

  /* color: ${({ theme }) => theme.text}; */

  .app__title {
    color: ${colors.primary};
    letter-spacing: 4px;
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  .app__desc {
    color: ${({ theme }) => theme.text};
    font-size: 48px;
    margin: 20px 0;
    padding: 0;
    font-family: "Rubik-Bold";

    span {
      color: #e25a5a;
    }

    @media (max-width: 500px) {
      padding: 0;
      font-size: 35px;
      margin: 5px 0;
    }
  }

  .app__text {
    color: ${({ theme }) => theme.subText};
    font-size: 20px;
    display: flex;
    gap: 12px;

    & img {
      width: 18px;
      height: 15px;
      margin-top: 5px;
    }
  }

  .app__pro {
    width: 83%;
    padding-top: 28px;
    padding-bottom: 78px;
    font-size: 18px;
    color: #162e38;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 85%;
      text-align: center;
      line-height: 26px;
    }

    @media (max-width: 500px) {
      text-align: center;
      line-height: 26px;
      font-size: 14px;
    }

    input {
      background: none;
      border: none;
      outline: none;
    }

    button {
      background-color: ${colors.primary};
      border: none;
      outline: none;
      color: white;
      padding: 16.5px 25px;
      border-radius: 5px;
    }
  }

  .app__download {
    p {
      color: ${({ theme }) => theme.text};
      font-size: 16px;
    }

    .download__label {
      margin-bottom: 30px !important;
      color: #162e38;

      @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 25px;
      }
    }

    .download__sect {
      text-align: left;
      display: flex;
      justify-content: center;
    }
  }

  .app__rates {
    background: white;
    width: fit-content;
    margin: 0 auto;
    box-shadow: 0px 20px 20px #0000000d;
    border: 1px solid #f2f6f7;
    border-radius: 24px;
    opacity: 1;
    padding: 51px 45px;
    margin-top: 50px;
    position: relative;
    margin-bottom: 50px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;

      p {
        &:nth-child(1) {
          color: #162e38;
          font-size: 18px;
          font-family: "Rubik-Bold";
        }

        &:nth-child(2) {
          color: #162e38;
          font-size: 14px;
          font-family: "Rubik-Bold";
        }
      }
    }

    &__input__grid {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      gap: 15px;

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }

      .rates__input {
        display: flex;
        /* align-items: center; */
        margin-bottom: 20px;

        input {
          background-color: #f2f6f7;
          color: #67777e;
          font-size: 14px;
          padding: 0px 20px;
          height: 60px;
          width: 296px;
          border: none;
          outline: none;
          border-radius: 0px 12px 12px 0px;
          border-left: 1px solid #67777e50;

          @media (max-width: 1043px) {
            width: 200px;
          }
          @media (max-width: 1020px) {
            width: 150px;
          }
          @media (max-width: 500px) {
            font-size: 12px;
          }
        }

        &__cta {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          &__grid {
            background-color: #f2f6f7;
            color: #67777e;
            padding: 0px 17px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px 0 0 12px;
            cursor: pointer;
            gap: 30.77px;
          }

          &__dropdown {
            position: absolute;
            top: 65px;
            background-color: #162e38;
            width: 90px;
            padding: 13px;
            color: #fff;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            z-index: 30;
          }

          &__item {
            font-size: 14px;
            color: white;
            cursor: pointer;

            &:hover {
              color: #e25a5a;
            }
          }

          &__text {
            p {
              &:nth-child(1) {
                font-size: 10px;
              }
              &:nth-child(2) {
                font-size: 14px;
                color: #162e38;
                font-family: "Rubik-Medium";
              }
            }
          }
        }
      }
    }

    &__btn {
      position: absolute;
      align-self: center;
      bottom: -25px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .app__services {
    /* padding: 8.7rem 6.9rem 9.25rem 9rem; */
    padding-top: ${resp(139)};
    padding-right: ${resp(110)};
    padding-bottom: ${resp(30)};
    position: relative;
    padding-left: ${resp(148)};

    @media (max-width: 768px) {
      padding: 3.8rem 1.8rem;
    }
    @media (max-width: 480px) {
      padding: 3.8rem 1.8rem;
    }

    &__label {
      text-align: center;
      font-family: "Rubik-Bold";
      font-size: 32px;

      @media (max-width: 480px) {
        font-size: 28px;
        width: 70%;
        margin: 0 auto;
      }
    }

    &__grid {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      flex: 1 0 auto;
      gap: 16px;
      position: relative;
      margin-top: 106px;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin-top: 67px;
      }

      .grid__item {
        width: 48%;
        max-width: calc(100% - 50px);
        height: 572px;
        border-radius: 50px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        @media (max-width: 768px) {
          width: 100%;
          height: 453px;
          border-radius: 20px;
          margin: 0 auto;
        }

        &__text {
          width: 70%;
          padding: 83px 51px;

          @media (max-width: 480px) {
            padding: 64px 20px;
            width: 90%;
          }

          h3 {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 10px;
            font-family: "Rubik-Bold";

            @media (max-width: 480px) {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
            }
          }
          p {
            font-size: 16px;
            line-height: 22px;

            @media (max-width: 480px) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }

      .float__img {
        position: absolute;
        bottom: -90px;
        right: -50px;

        img {
          width: 350px;
        }

        @media (max-width: 768px) {
          bottom: -30px;
          right: -20px;
        }

        @media (max-width: 396px) {
          display: none;
        }

        img {
          @media (max-width: 768px) {
            width: 200px;
          }
        }
      }
    }

    .app__cta {
      background-color: #f2f6f7;
      border-radius: 50px;
      padding: 100px 70px;
      margin-top: 87px;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;

      @media (max-width: 480px) {
        border-radius: 20px;
      }

      @media (max-width: 939px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 50px auto;
        align-items: center;
        height: 600px;
        padding: 50px 0;
      }

      &__left {
        width: 50%;
        max-width: calc(100% - 50px);

        h3 {
          font-family: "Rubik-Bold";
        }

        @media (max-width: 939px) {
          width: 100%;
          text-align: center;
        }

        @media (max-width: 480px) {
          text-align: left;
        }

        .app__cta__text {
          font-size: 14px;
          margin-bottom: 50px;

          @media (max-width: 480px) {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 22px;
          }
        }

        h3 {
          font-size: 32px;
          width: 80%;
          margin-bottom: 45px;

          @media (max-width: 939px) {
            width: 100%;
          }

          @media (max-width: 480px) {
            text-align: left;
            font-size: 24px;
            width: 80%;
          }
        }

        .download__sect {
          margin-top: 20px;
          margin-bottom: 58px;

          @media (max-width: 500px) {
            margin-bottom: 0;
          }

          @media (max-width: 939px) {
            display: flex;
            justify-content: center;
          }
        }
      }

      &__right {
        position: absolute;
        right: 10%;

        img {
          width: 300px;
        }

        @media (max-width: 939px) {
          position: relative;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          margin: 0 auto;
          align-items: center;
          right: 0;

          img {
            text-align: center;
          }
        }
      }
    }
  }

  .waitlist {
    margin-top: 190px;

    @media (max-width: 500px) {
      margin-top: 100px;
    }

    h3 {
      width: 45%;
      margin: 0 auto;

      @media (max-width: 500px) {
        width: 90%;
      }
    }

    .app__pro {
      width: 45%;
      padding-top: 28px;
      padding-bottom: 78px;
      font-size: 16px;
      color: #162e38;
      margin: 0 auto;
      text-align: center;

      @media (max-width: 500px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 85%;
        text-align: center;
        line-height: 26px;
      }
    }

    &__banner {
      padding: 51px 45px;
      border-radius: 24px;
      background-color: #152e38;
      height: 150px;
      display: flex;
      color: white;
      justify-content: space-between;
      width: 65%;
      max-width: calc(100% - 50px);
      margin: 0 auto;
      position: relative;
      overflow: hidden;

      @media (max-width: 566px) {
        height: fit-content;
      }
      @media (max-width: 500px) {
        width: 80%;
      }
    }

    &__image {
      position: absolute;
      left: -150px;
      bottom: -30px;
      display: block;

      @media (max-width: 1397px) {
        display: none;
      }
    }

    &__formed {
      position: absolute;
      right: 120px;
      width: 50%;
      top: 40%;
      display: flex;
      gap: 30px;
      align-items: center;

      img {
        /* width: 30px; */
      }
    }

    &__form {
      position: absolute;
      right: 120px;
      width: 50%;

      @media (max-width: 1397px) {
        position: relative;
        right: 0px;
        width: 80%;
      }
      @media (max-width: 500px) {
        width: 100%;
        align-self: center;
        text-align: center;
      }

      .form__label {
        font-family: "Rubik-Bold";
        font-size: 20px;
        width: 80%;
        margin-bottom: 10px;

        @media (max-width: 500px) {
          width: 100%;
        }
      }
      .form__extra {
        font-size: 12px;
        color: #54676e;
        margin-bottom: 15px;
      }
    }
    &__input {
      display: flex;
      gap: 12px;

      @media (max-width: 566px) {
        display: flex;
        flex-direction: column;
      }

      input {
        background-color: "#F2F6F7";
        outline: none;
        border: none;
        border-radius: 12px;
        padding: 20px;
        width: 200px;

        @media (max-width: 500px) {
          width: 85%;
          align-self: center;
        }
      }

      button {
        background-color: #e25a5a;
        outline: none;
        border: none;
        border-radius: 14px;
        padding: 20px 50px;
        color: "#ffffff";
      }
    }
  }
`
