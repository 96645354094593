import styled from "styled-components"

export const Container = styled.button`
  padding: 15px 40px;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  img {
    width: 25px;
  }

  button {
    border: none;
    outline: none;
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    font-size: 14px;
    width: 100%;
  }
`
