import { FC } from "react"
import { Container } from "./styles"

interface Props {
  title?: string
  icon?: string
  loading?: boolean
  onClick?: () => void
  btnStyle?: any
  textStyle?: any
  disabled?: boolean
}

const Button: FC<Props> = ({
  title,
  icon,
  loading,
  onClick,
  btnStyle,
  textStyle,
  disabled,
}) => {
  return (
    <Container
      style={btnStyle}
      onClick={onClick}
      type="submit"
      disabled={loading || disabled}
    >
      {icon && <img src={icon} alt="" />}
      <p style={textStyle}>{loading ? "Please Wait" : title}</p>
    </Container>
  )
}

export default Button
